import { displayDate } from '@/helpers/form/formatting'
import { timeHeader } from '@/helpers/ag-grid/headerFormats'

export default [
    {
        headerName: 'Component',
        field: 'component',
    },
    {
        headerName: timeHeader('Date Time'),
        field: 'dateTime',
        valueFormatter: (date) => displayDate(date.value),
    },
    {
        headerName: 'Message Type',
        field: 'messageType',
    },
    {
        headerName: 'Message',
        field: 'message',
        width: 600,
    },
]
